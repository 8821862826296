@use "../../../../global-styles/palette" as c;

.card-with-image-and-button {
    border-radius: 20px;
    min-width: 327px;
    background: linear-gradient(
        to bottom right,
        #1c073d 0%,
        #1a1e4c 31%,
        #0e0f31 50%
    );
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;

    &:dir(rtl) {
        align-items: unset;
    }

    &__text-and-button-wrapper {
        max-width: 146px;
        padding: 27px 0px 27px 20px;
        display: flex;
        flex-direction: column;
        gap: 6px;

        &:dir(rtl) {
            padding: 27px 20px 27px 0px;
        }

        .button-v2-wrapper .secondary-large-wbg {
            max-width: 50px;
            padding: 6px 16px;
            margin: 6px 0px 0px 0px;
            font-size: 11px;
            font-weight: 600;
            line-height: 14px;
            border-radius: 38px;
            color: c.$dark-default;
            background-color: c.$pure-white;

            &:hover {
                color: c.$dark-default;
                background-color: #e2e8f0;
            }
        }

        .button-v2-wrapper {
            margin: 0px;
            justify-content: unset;
        }
    }

    &__image-wrapper {
        align-self: flex-end;
        display: flex;
    }
}

.small-view {
    .card-with-image-and-button {
        &__text-and-button-wrapper {
            h4 {
                font-size: 17px;
                font-weight: 700;
                line-height: 120%;
                color: c.$pure-white;
            }

            p {
                font-size: 12px;
                font-weight: 400;
                line-height: 140%;
                color: c.$neutral-accent-light-grey-font;
            }
        }

        &__image-wrapper {
            img {
                width: 156px;
            }
        }
    }
}

.medium-view {
    .card-with-image-and-button {
        &__text-and-button-wrapper {
            max-width: 261px;
            padding: 47px 0px 47px 31px;
            gap: 10px;

            &:dir(rtl) {
                padding: 47px 31px 47px 0;
            }

            h4 {
                font-size: 28px;
                font-weight: 700;
                line-height: 120%;
                color: c.$pure-white;
            }

            p {
                font-size: 14px;
                font-weight: 400;
                line-height: 140%;
                color: c.$neutral-accent-light-grey-font;
            }

            .button-v2-wrapper .secondary-large-wbg {
                max-width: 84px;
                padding: 15px 27px;
                font-size: 16px;
                margin: 10px 0px 0px 0px;
            }
        }
    }
}

.large-view {
    .card-with-image-and-button {
        &__text-and-button-wrapper {
            max-width: 261px;
            padding: 47px 0px 47px 31px;
            gap: 10px;

            &:dir(rtl) {
                padding: 47px 31px 47px 0;
            }

            h4 {
                font-size: 28px;
                font-weight: 700;
                line-height: 120%;
                color: c.$pure-white;
            }

            p {
                font-size: 14px;
                font-weight: 400;
                line-height: 140%;
                color: c.$neutral-accent-light-grey-font;
            }

            .button-v2-wrapper .secondary-large-wbg {
                max-width: 84px;
                padding: 15px 27px;
                font-size: 16px;
                margin: 10px 0px 0px 0px;
            }

            .button-v2-wrapper .secondary-large-wbg:hover {
                background-color: c.$dark-10;
            }
        }
    }
}
