@use "../../../global-styles/palette" as c;

@use "./CardWithImageAndButton/CardWithImageAndButton";

.two-cards-with-images-and-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;

    &__text-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 327px;

        h2 {
            color: c.$dark-default;
            text-align: center;
        }
    }

    &__cards-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;

        &:dir(rtl) {
            align-items: unset;
        }
    }
}

.medium-view {
    .two-cards-with-images-and-button {
        &__text-wrapper {
            max-width: 600px;
        }

        &__cards-wrapper {
            gap: 24px;
        }
    }
}

.large-view {
    .two-cards-with-images-and-button {
        &__text-wrapper {
            max-width: 744px;
        }

        &__cards-wrapper {
            gap: 24px;
            flex-direction: row;
            align-items: stretch;
        }
    }
}
